<template>
  <div class="container px-5 animate__animated animate__fadeIn">
      
    <div class="row pb-3">
      <div class="col-12">
        <button v-on:click="goToBef()" class="btn btn-white text-muted text-decoration-none">
          <span class="small"><i class="fas fa-arrow-left"></i></span>
          <span class="ml-3">Regresar</span>
        </button>
      </div>
      </div>
      
      <component v-for="component in currentComponent" :key="component" :is="component" />

      <div class="row pt-3">
      <div class="col-12"><p class="small text-muted text-center">X-orsystem 2020 &copy; - Single Sign On</p></div>
    </div>
      
  </div>
</template>

<script>
export default {
  title: 'X-orsystem Auth',
  name: 'LoginUsername',
  data() {
    return {
      currentComponent: [],
      currentStep: null,
      active: {
        login: null,
        register: null,
        recovery: null
      },
    }
  },
  components: {
    cloginusername: () => import('@/components/login/Username.vue'),
    cloginoptions: () => import('@/components/login/Options.vue'),
    cloginpassword: () => import('@/components/login/Password.vue'),
    clogindevice: () => import('@/components/login/Device.vue'),
    clogged: () => import('@/components/login/Logged.vue'),
    cloginfingerprint: () => import('@/components/login/Fingerprint.vue'),
    cregisterbutton: () => import('@/components/register/Button.vue'),
  },
  methods: {
    checkStep() {
      this.currentComponent = []

      switch (this.currentStep) {
        case "cloginusername":
          this.currentComponent.push(this.currentStep)
          this.currentComponent.push("cregisterbutton")
          break

        case "cloginoptions":
          this.$store.commit("setBefStep", "cloginusername")
          this.currentComponent.push(this.currentStep)
          break

        case "cloginpassword":
          this.$store.commit("setBefStep", "cloginusername")
          this.currentComponent.push(this.currentStep)
          break

        case "clogindevice":
          this.$store.commit("setBefStep", "cloginusername")
          this.currentComponent.push(this.currentStep)
          break

        case "cloginfingerprint":
          this.$store.commit("setBefStep", "cloginusername")
          this.currentComponent.push(this.currentStep)
          break

        case "clogged": this.currentComponent.push(this.currentStep); break
      }
    },
    goTo() {
      this.currentStep = this.$store.getters.getCurrentStep
      this.checkStep()
    },
    goToBef() {
      if(this.currentStep == "cloginusername") window.location = 'https://x-orsystem.com'
      else {
        this.currentStep = this.$store.getters.getBefStep
        this.checkStep()
      }
    }
  },
  computed: { step() {return this.$store.getters.getCurrentStep } },
  watch: { step() { this.goTo() } },
  mounted() {
    if(this.$cookies.isKey("__xor_user")) {
      console.log('logged')
      this.$store.commit('setCurrentStep', "clogged")
      this.goTo()
    } else {
      this.$store.commit('setCurrentStep', "cloginusername")
    }
  },
}
</script>